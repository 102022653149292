import React from "react";
import { graphql } from "gatsby";
import BlogSummary from "../../components/Blog/Summary/Summary";
import CopyRights from "../../components/Footer/CopyRights/CopyRights";
import Layout from "../../components/layout";
import ArticlesComponent from "../../components/articles";
import "../../assets/css/main.css";
import MainMenu from "../../components/MainMenu/MainMenu";
import Footer from "../../components/Footer/Footer";
import HeadingCTA from "../../components/HeadingCTA/HeadingCTA";
import Container from "@material-ui/core/Container";
import { StaticImage } from "gatsby-plugin-image";
import Header from "../../components/Header/Header";
import { ThemeProvider } from "@material-ui/core/styles";
import Img from "gatsby-image";

const IndexPage = props => {
  const { data, pageContext } = props;

  return (
    <Layout seo={pageContext.meta.seo}>
      <MainMenu />

      <Header
        image={
          <StaticImage
            src="../../assets/images/blog/header.jpg"
            alt="world class event transportation"
          />
        }
        halfHeight
        breadcrumbs={[{ label: "Blog" }]}
      >
        <HeadingCTA dark title={"Blog"} h1 />
      </Header>
      <BlogSummary
        articles={data.allStrapiArticle.edges}
        pageContext={pageContext}
      />
      <Footer />
      <CopyRights />
    </Layout>
  );
};

export const query = graphql`
  query blogListQuery($skip: Int, $limit: Int) {
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          strapiId
          slug
          title
          content
          publishedAt(formatString: "MMMM D, YYYY")
          imageAlt
          description 
          category {
            name
          }
          categories {
            name
            slug
          }
          image {
            childImageSharp {
              fluid(maxHeight: 444) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          author {
            name
            picture {
              childImageSharp {
                fixed(width: 30, height: 30) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;

// picture {
//   childImageSharp {
//     fixed(width: 30, height: 30) {
//       src
//     }
//   }
// }

// image {
//   childImageSharp {
//     fixed(width: 800, height: 500) {
//       src
//     }
//   }
// }
