import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../../Typography/Typography";
import Button from "../../Button/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { blogSummaryList } from "./Summary.helper";
import LetsGetStarted from "../../LetsGetStarted/LetsGetStarted";
import { mobileViewBreakpoint } from "../../helper.js";
import CircularIconButton from "../../Button/CircularIconButton";
import { StaticImage } from "gatsby-plugin-image";

import Img from "gatsby-image";
import { Link } from "gatsby";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "0px"
    }
  },
  blogSummaryImage: {
    flex: "0.5",
    height: "444px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: "200px",
      objectFit: "cover",
      flex: "none"
    }
  },
  blogSummary: {
    display: "flex",
    maxWidth: 1234,
    paddingLeft: 32,
    paddingRight: 32,
    margin: "0 auto 80px auto",
    overflowX: "hidden",
    boxSizing: "border-box",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
      padding: 0
    },
    "&:first-child": {
      marginTop: 128,
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginTop: 0
      }
    }
  },
  summaryContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0px 48px 0px 32px",
    flex: "0.5",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px"
    }
  },
  summaryType: {
    color: "#CC4A01",
    fontWeight: "bold",
    margin: "28px 0px 16px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "16px 0px 8px 0px"
    }
  },

  summaryTypeLink: {
    color: "#CC4A01"
  },

  summaryTitle: {
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "bold",
    color: "#000"
  },
  summaryDate: {
    color: "rgba(0,0,0,0.5)",
    margin: "24px 0px",
    fontSize: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "8px 0px"
    }
  },
  summaryDesc: {
    color: "rgba(0,0,0,0.6)",
    marginBottom: "32px",
    fontSize: "20px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "0px 0px 24px 0px"
    }
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "32px",

    "& > a": {
      width: "40px",
      height: "40px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      border: "2px solid #CC4A01",
      color: "#CC4A01!important",
      margin: "0px 10px",
      boxShadow: "0px 6px 10px rgba(66, 75, 90, 0.2)",
      "&:hover": {
        textDecoration: "none"
      },
      "&.active": {
        backgroundColor: "#CC4A01",
        color: "#fff!important",
        fontWeight: "bold"
      }
    }
  }
}));

const Summary = ({ articles, pageContext }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div className={classes.root}>
      {articles.map(post => {
        const lastElement =
          post.node.categories[post.node.categories.length - 1];

        return (
          <div className={classes.blogSummary}>
            <Img
              fluid={post.node.image.childImageSharp.fluid}
              className={classes.blogSummaryImage}
              alt={post.node.imageAlt}
            />

            <div className={classes.summaryContent}>
              <br />

              <Typography
                customVariant={"h5Regular"}
                className={classes.summaryType}
              >
                {post.node.categories.map(category => (
                  <>
                    <span
                      {
                        ...{} /* href={"/category/" + category.slug} */
                      }
                      className={classes.summaryTypeLink}
                    >
                      {category.name}
                    </span>
                    {category.slug != lastElement.slug && <>, </>}
                  </>
                ))}
              </Typography>

              <a
                href={"/blog/" + post.node.slug + "/"}
                style={{ maxWidth: isMobileView ? undefined : 400 }}
              >
                <Typography customVariant={isMobileView ? "h4Bold" : "h3Bold"}>
                  {post.node.title}
                </Typography>
              </a>
              <Typography
                customVariant={"h5Bold"}
                className={classes.summaryDate}
              >
                {post.node.author.name} · {post.node.publishedAt}
              </Typography>
              <Typography
                customVariant={"h5Regular"}
                className={classes.summaryDesc}
              >
                {post.node.description}
              </Typography>

              {/*
              <a href={"/blog/" + post.node.slug}>
                <Button>Read More</Button>
              </a>
              */}
            </div>
          </div>
        );
      })}

      {pageContext && (
        <div className={classes.pagination}>
          {[...Array(pageContext.numPages).keys()].map(key => (
            <a
              href={key == 0 ? "/blog" : "/blog/page-" + (key + 1)}
              className={pageContext.currentPage == key ? "active" : ""}
            >
              <span>{key + 1}</span>
            </a>
          ))}
        </div>
      )}

      <LetsGetStarted />
    </div>
  );
};

export default Summary;
