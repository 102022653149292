import React from 'react';
import PropTypes from 'prop-types';

const getStyles = (size, styles) => {
  if (size === 'small') return styles.small;
  return styles.large;
};

const generalButtonStyles = {
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: 0,
  border: 'none',
};

const styles = {
  small: {
    ...generalButtonStyles,
    height: 24,
    width: 24,
  },
  large: {
    ...generalButtonStyles,
    height: 32,
    width: 32,
  },
};

const CircularIconButton = ({
  onClick,
  children,
  size = 'small',
  style = {},
}) => {
  const buttonStyles = getStyles(size, styles);

  return (
    <button
      type="button"
      style={{ ...buttonStyles, ...style }}
      onClick={onClick}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </span>
    </button>
  );
};

CircularIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default CircularIconButton;
